const useReload = () => {
    const reload = () => {
        const unloadCallback = (event) => {
            if (window.location.pathname.startsWith('/edit-profile')) {
                event.preventDefault();
                event.returnValue = "";
                return "";
            }
        };

        window.addEventListener("beforeunload", unloadCallback);
    }

    return reload;
}

export default useReload;