import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import * as actions from "./modules/Auth/_redux/authActions";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import useHeader from "./modules/Auth/_redux/authHeader";
import EditUserProfile from "./pages/EditUserProfile";
import Leads from "./pages/Leads";
import { Suspense } from "react";
import { LayoutSplashScreen } from "./components/splashScreen/SplashScreen";

const FailPage = React.lazy(() => import("./pages/errorPages/FailPage"));
const SuccessPage = React.lazy(() => import("./pages/errorPages/SuccessPage"));
const NotFound = React.lazy(() => import("./pages/errorPages/404NotFound"));
const ComingSoon = React.lazy(() => import("./pages/errorPages/ComingSoon"));
const NewCardEmail = React.lazy(() =>
  import("./components/newCardEmail/NewCardEmail")
);
const NewUserPassword = React.lazy(() =>
  import("./components/newUserPassword/NewUserPassword")
);
const DisplayToOthersMain = React.lazy(() =>
  import("./components/displayToOthersMain/DisplayToOthersMain")
);
const Login = React.lazy(() => import("./modules/Auth/pages/Login"));
const ForgotPassword = React.lazy(() =>
  import("./modules/Auth/pages/ForgotPassword")
);
const RequireAuth = React.lazy(() => import("./modules/Auth/RequireAuth"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const Home = React.lazy(() => import("./pages/Home"));
const EditProfile = React.lazy(() => import("./pages/EditProfile"));
const RecoverPassword = React.lazy(() =>
  import("./modules/Auth/pages/RecoverPassword")
);
const VerifyOTP = React.lazy(() => import("./components/verifyOTP/VerifyOTP"));
const RootRoutes = () => {
  const dispatch = useDispatch();
  const [header] = useHeader();

  // useEffect(() => {
  //   dispatch(actions.getUserData(header));
  // }, [auth.user]);

  useEffect(() => {
    if (Cookies.get("token")) {
      dispatch(actions.getUserData(header));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth redirectTo="/login">
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/change-password"
            element={
              <RequireAuth redirectTo="/login">
                <ChangePassword />
              </RequireAuth>
            }
          />
          <Route
            path="/edit-user-profile"
            element={
              <RequireAuth redirectTo="/login">
                <EditUserProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/leads"
            element={
              <RequireAuth redirectTo="/login">
                <Leads />
              </RequireAuth>
            }
          />
          <Route
            path="/edit-profile/:id/:profileId"
            element={
              <RequireAuth redirectTo="/login">
                <EditProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/edit-profile/:id/:profileId/:isTrue"
            element={
              <RequireAuth redirectTo="/login">
                <EditProfile />
              </RequireAuth>
            }
          />
          <Route path="/:cardId" element={<DisplayToOthersMain />} />
          <Route
            path="/forgot/password/:userid/:token"
            element={<RecoverPassword />}
          />
          <Route path="/:cardId/send-otp" element={<NewCardEmail />} />
          <Route path="/:cardId/verify-otp" element={<VerifyOTP />} />
          <Route
            path="/:cardId/create-password"
            element={<NewUserPassword />}
          />
          <Route path="/payment-ref/successful" element={<SuccessPage />} />
          <Route path="/payment-ref/fail" element={<FailPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default RootRoutes;
