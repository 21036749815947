import React from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const languageMap = {
  en: { label: "English", dir: "ltr", active: true },
  // ar: { label: "العربية", dir: "rtl", active: false },
  fr: { label: "Français", dir: "ltr", active: false },
  hi: { label: "हिन्दी", dir: "ltr", active: false },
  chi: { label: "中国人", dir: "ltr", active: false },
  gu: { label: "ગુજરાતી", dir: "ltr", active: false },
  es: { label: "española", dir: "ltr", active: false },
};

const LanguageDropdown = () => {
  const selected = localStorage.getItem("i18nextLng") || "en";
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);
  const { t } = useTranslation();
  return (
    <>
      <li className="list-inline-item mb-0 ms-1">
        <div className="dropdown dropdown-primary">
          <button
            type="button"
            className="btn btn-icon btn-soft-light dropdown-toggle p-0"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
          >
            <i className="ti ti-world"></i>
          </button>

          <div
            className="dropdown-menu dd-menu bg-white shadow rounded border-0 mt-3 p-0"
            data-simplebar
            style={{ height: "auto", width: 150 }}
          >
            <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
              <h6 className="mb-0 text-dark">{t(`select_langauage`)}</h6>
              {/* <i className="ti ti-world"></i> */}
            </div>
            <div className="p-3">
              {Object.keys(languageMap).map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    i18next.changeLanguage(item);
                    setMenuAnchor(null);
                  }}
                  style={{ cursor: "pointer" }}
                  className="dropdown-item features text-center feature-primary key-feature p-0 "
                >
                  <div className="d-flex align-items-center">
                    {/* <div className="icon text-center rounded-circle me-2">EN</div> */}
                    <div className="flex-1">
                      <h6 className="mb-0 text-dark title">
                        {languageMap[item].label}
                      </h6>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default LanguageDropdown;
