import axios from "axios";

const BASE_URL = process.env.REACT_APP_URL;
export const LOGIN_URL = `${BASE_URL}user/login`;
export const FORGOT_PASSWORD = `${BASE_URL}user/sendlink`;
export const CHANGE_FORGOT_PASSWORD = `${BASE_URL}user/forgot/password`;
export const VERIFY_FORGOT_TOKEN = `${BASE_URL}user/auth/forgot`;
export const GET_USER_DATA = `${BASE_URL}user/common/info/`;
export const CHANGE_USER_PASSWORD = `${BASE_URL}user/change/password`;
export const USER_EDIT_ID = `${BASE_URL}user/edit/`;
export const UPDATE_USER = `${BASE_URL}user/save/profile`;
export const GET_LEADS_BY_ID = `${BASE_URL}user/common/leads/`;
export const GET_ALL_USER_CARDS = `${BASE_URL}user/common/cards/`;
export const GET_CARD_BY_ID = `${BASE_URL}user/common/card/`;
export const CHANGE_CARD_PROFILE = `${BASE_URL}user/common/profile/`;
export const PUBLISH_USER_CARD = `${BASE_URL}user/common/card/`;
export const DELETE_USER_CARD = `${BASE_URL}user/common/card/`;
export const GET_CARD_PROFILE_BY_ID = `${BASE_URL}user/common/profile/`;
export const ADD_PROFILE = `${BASE_URL}user/common/profile`;
export const DELETE_PROFILE = `${BASE_URL}user/common/profile/`;
export const PROFILE_CUSTOM_LINK = `${BASE_URL}user/common/profile/`;
export const GET_PROFILE_TYPES = `${BASE_URL}user/common/card_type`;
export const GET_CARD_CATEGORY = `${BASE_URL}user/common/category`;
export const GET_CARD_OPTIONS = `${BASE_URL}user/common/templates`;
export const DISPLAY_USER_CARD = `${BASE_URL}user/v1/link/`;
export const NEW_CARD_EMAIL = `${BASE_URL}user/sendotp`;
export const NEW_USER_PASSWORD = `${BASE_URL}user/save/password`;
export const VERIFY_USER_EMAIL = `${BASE_URL}user/verifyotp`;
export const CHECK_EXISTING_USER = `${BASE_URL}user/data/id/`;
export const GET_ALL_ORGANISATIONS = `${BASE_URL}user/common/org`;
export const VERIFY_ORGANISATION = `${BASE_URL}user/common/org/code_verify`;
export const EDIT_DEPARTMENT_ARRAY = `${BASE_URL}user/common/org/`;
export const EDIT_CARD_NAME = `${BASE_URL}user/common/card/`;
export const CHANGE_CARD_DEPARTMENT = `${BASE_URL}user/common/card/assign`;
export const UPDATE_EMAIL_SIGNATURE = `${BASE_URL}user/common/card/`;
export const TAP_CARD = `${BASE_URL}user/common/tap`;
export const REPLACE_CARD_STRIPE = `${BASE_URL}admin/common/order`;
export const ADDON_CARD_STRIPE = `${BASE_URL}admin/common/addon/order`;
export const REPLACE_SIMPLE_REPLACE = `${BASE_URL}admin/common/replace_card`;
export const GENERATE_LEADS = `${BASE_URL}user/common/leads`;
export const GET_BUSINESS_LEADS_BY_ID = `${BASE_URL}user/common/org_users/`;
export const SEND_WALLET_LINK = `${BASE_URL}user/common/wallet/card`

export function login(email, password, rememberMe) {
  return axios.post(LOGIN_URL, {
    email: email,
    password: password,
    rememberMe: rememberMe,
  });
}

export function forgotPassword(email) {
  return axios.post(FORGOT_PASSWORD, { email: email });
}
export function forgotPasswordChange(body) {
  return axios.post(CHANGE_FORGOT_PASSWORD, body);
}

export function verifyForgotToken(userid, token) {
  return axios.post(VERIFY_FORGOT_TOKEN, {
    userId: userid,
    forgot_token: token,
  });
}
export function getUserData(headers) {
  return axios.get(GET_USER_DATA + headers["x-authorization"], {
    headers: headers,
  });
}

export function changeUserPassword(headers, body) {
  return axios.patch(CHANGE_USER_PASSWORD, body, { headers: headers });
}

export function userEdit(headers, body) {
  return axios.patch(USER_EDIT_ID + headers["x-authorization"], body, {
    headers: headers,
  });
}

export function updateUserData(headers, body) {
  return axios.patch(UPDATE_USER, body, { headers: headers });
}

export function getUserLeadById(headers) {
  return axios.get(GET_LEADS_BY_ID + headers["x-authorization"], {
    headers: headers,
  });
}

export function getAllUserCards(headers) {
  return axios.get(GET_ALL_USER_CARDS + headers["x-authorization"], {
    headers: headers,
  });
}

export function getUserCardById(headers, id) {
  return axios.get(GET_CARD_BY_ID + id, {
    headers: headers,
  });
}
export function getUserCardProfileById(headers, id) {
  return axios.get(GET_CARD_PROFILE_BY_ID + id, {
    headers: headers,
  });
}
export function changeUserProfile(headers, profileId, cardId) {
  return axios.patch(
    CHANGE_CARD_PROFILE + profileId + "/" + cardId,
    { is_active: true },
    { headers: headers }
  );
}

export function publishUserCard(headers, cardId, publishStatus) {
  return axios.patch(
    PUBLISH_USER_CARD + cardId,
    { is_published: publishStatus },
    { headers: headers }
  );
}

export function deleteUserCard(headers, cardId) {
  return axios.delete(DELETE_USER_CARD + cardId, { headers: headers });
}

export function addProfile(headers, body) {
  return axios.post(ADD_PROFILE, body, { headers: headers });
}
export function deleteProfile(headers, cardId, profileId) {
  return axios.delete(DELETE_PROFILE + profileId + "/" + cardId, {
    headers: headers,
  });
}
export function getProfileTypes(headers) {
  return axios.get(GET_PROFILE_TYPES, {
    headers: headers,
  });
}
export function profileCustomLink(headers, cardId, profileId, customLink, id) {
  return axios.patch(
    PROFILE_CUSTOM_LINK + profileId + "/" + cardId,
    { custom_link: customLink, profile_type_id: id },
    {
      headers: headers,
    }
  );
}

export function setProfileData(headers, cardId, profileId, body) {
  return axios.patch(PROFILE_CUSTOM_LINK + profileId + "/" + cardId, body, {
    headers: headers,
  });
}

export function getCardCategory(headers) {
  return axios.get(GET_CARD_CATEGORY, {
    headers: headers,
  });
}
export function getCardOptions(headers) {
  return axios.get(GET_CARD_OPTIONS, {
    headers: headers,
  });
}
export function displayUserCard(cardId) {
  return axios.get(DISPLAY_USER_CARD + cardId);
}

//New card requests
export function newCardEmail(body) {
  return axios.post(NEW_CARD_EMAIL, body);
}

export function checkOtp(otp, cardId) {
  return axios.post(VERIFY_USER_EMAIL, { card_id: cardId, otp: otp });
}
export function testExistingUser(id) {
  return axios.get(CHECK_EXISTING_USER + id);
}

export function newUserPassword(password, cpassword, userId) {
  return axios.post(NEW_USER_PASSWORD, {
    password: password,
    cpassword: cpassword,
    userId: userId,
  });
}

export function getAllOrganisation() {
  return axios.get(GET_ALL_ORGANISATIONS);
}

export function verifyOrganisation(code) {
  return axios.post(VERIFY_ORGANISATION, {
    unique_code: code,
  });
}

export function addToDepartmentArray(headers, orgId, body) {
  return axios.patch(EDIT_DEPARTMENT_ARRAY + orgId, body, {
    headers: headers,
  });
}

export function deleteDepartmentArrayItem(headers, orgId, body) {
  return axios.patch(EDIT_DEPARTMENT_ARRAY + orgId, body, {
    headers: headers,
  });
}
export function editCardName(headers, cardId, body) {
  return axios.patch(EDIT_CARD_NAME + cardId, body, {
    headers: headers,
  });
}
export function editEmailSignature(headers, cardId, body) {
  return axios.patch(UPDATE_EMAIL_SIGNATURE + cardId, body, {
    headers: headers,
  });
}

export function changeDepartment(headers, body) {
  return axios.post(CHANGE_CARD_DEPARTMENT, body, { headers: headers });
}

export function tapCard(body) {
  return axios.post(TAP_CARD, body);
}

export function replaceCardStripe(headers, body) {
  return axios.post(REPLACE_CARD_STRIPE, body, { headers: headers });
}

export function addOnCardStripe(headers, body) {
  return axios.post(ADDON_CARD_STRIPE, body, { headers: headers });
}

export function requestSimpleReplace(headers, body) {
  return axios.patch(REPLACE_SIMPLE_REPLACE, body, { headers: headers });
}

export function generateLeads(headers, body) {
  return axios.post(GENERATE_LEADS, body, { headers: headers });
}
export function getBusinessLead(headers, id) {
  return axios.get(GET_BUSINESS_LEADS_BY_ID + id, {
    headers: headers,
  });
}

export function sendWalletLink(headers, body) {
  return axios.post(SEND_WALLET_LINK, body, { headers: headers });
}
