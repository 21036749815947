import React, { useEffect, useRef, useState } from "react";
import * as actions from "../modules/Auth/_redux/authActions";
import Navbar from "../components/navbar/Navbar";
import useHeader from "../modules/Auth/_redux/authHeader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Footer from "../components/footer/Footer";
import { useTranslation } from "react-i18next";
import ImageEditor from "../components/imageEditor/ImageEditor";
import Avatar from "../../assets/images/icon/avatar.png";

const EditUserProfile = () => {
  const dispatch = useDispatch();
  const [headers] = useHeader();
  const { t } = useTranslation();
  const hiddenFileInput = React.useRef(null);
  const [profileImage, setProfileImage] = useState();
  const [fullName, setFullName] = useState("");
  const [designation, setDesignation] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const childRef = useRef();
  const [successMessage, setSuccessMessage] = useState();
  const [loader, setLoader] = useState(false);
  const { auth } = useSelector((state) => state);
  const [popup, setPopup] = useState(false);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object

        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      getBase64(file)
        .then((result) => {
          file["base64"] = result;

          setProfileImage({
            base64URL: result,
            file,
          });
        })
        .catch((err) => {});

      setProfileImage({
        file: e.target.files[0],
      });
    }
  };
  const handleImageClick = (event) => {
    // hiddenFileInput.current.click();
    setPopup(true);
  };

  useEffect(() => {
    if (auth.user) {
      setFullName(auth.user.full_name);
      setDesignation(auth.user && auth.user.designation);
      setProfileImage(auth.user.profile_pic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const onUpadateProfile = async (e) => {
    e.preventDefault();

    setLoader(true);
    let isOK = true;
    if (!fullName) {
      isOK = false;
      setLoader(false);
      setErrorMessage("Please fill all details");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
    let body = {
      user_id: auth.user && auth.user._id,
      full_name: fullName,
      profile_pic: profileImage
        ? profileImage.base64URL
          ? profileImage.base64URL
          : profileImage
        : profileImage,
      designation: designation
        ? designation
        : auth.user
        ? auth.user.designation
        : "",
    };

    if (isOK) {
      dispatch(actions.updateUserProfile(headers, body)).then((response) => {
        setLoader(false);

        if (response.status === 200) {
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setErrorMessage(response.response.data.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        }
      });
    }
  };
  const onPopupCloseClick = () => {
    setPopup(false);
    childRef.current.onClear();
  };

  return (
    <>
      <div className="page-wrapper landrick-theme">
        <main className="page-content bg-light">
          <Navbar />

          <div className="container-fluid">
            <div className="layout-specing">
              <div className="pt-5">
                <div className="row">
                  <div className="col-12"></div>
                  <div className="col-12">
                    <div className="form-signin p-4 bg-white rounded shadow">
                      <div
                        id="popupI"
                        style={{
                          display: `${popup ? "block" : "none"}`,
                        }}
                      >
                        <div className="popup-containerI">
                          <div className="popupI" style={{ zIndex: 2 }}>
                            <div
                              className="close-popupI"
                              onClick={onPopupCloseClick}
                              id="closeBtnI"
                            >
                              <a href="#!">X</a>
                            </div>
                            <h2>{t(`select_your_image`)}</h2>
                            <ImageEditor
                              setProfileImage={setProfileImage}
                              onPopupCloseClick={onPopupCloseClick}
                              ref={childRef}
                            />
                          </div>
                        </div>
                      </div>
                      <form>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                        />
                        <div onClick={handleImageClick}>
                          <img
                            style={{
                              cursor: "pointer",
                              borderRadius: 10,
                            }}
                            src={
                              profileImage && profileImage.base64URL
                                ? profileImage.base64URL
                                : profileImage
                                ? profileImage
                                : Avatar
                            }
                            className="avatar mb-3 d-block mx-auto"
                            alt=""
                            height={120}
                            width={120}
                          />
                        </div>
                        <h5 className="mb-1 text-center text-primary">
                          {t(`edit_profile`)}
                        </h5>
                        <p className="text-center text-primary mt-0">
                          {t(`click_edit_profile`)}
                        </p>
                        {successMessage && (
                          <p className="text-success text-center">
                            {successMessage}
                          </p>
                        )}
                        {errorMessage && (
                          <p className="text-danger text-center">
                            {errorMessage}
                          </p>
                        )}
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Current Password"
                            value={auth.user && auth.user.email}
                            disabled
                          />
                          <label htmlFor="email">{t(`email`)}</label>
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="fullName"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                          <label htmlFor="fullName">{t(`full_name`)}</label>
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="fullName"
                            placeholder="Designation"
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                          />
                          <label htmlFor="fullName">{t(`designation`)}</label>
                        </div>

                        <button
                          className="btn btn-primary w-100 mb-4"
                          type="submit"
                          onClick={onUpadateProfile}
                          disabled={loader}
                        >
                          {loader ? (
                            <div className="spinner-border spinner-border-sm"></div>
                          ) : (
                            t(`Save`)
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
};

export default EditUserProfile;
