import * as requestFromServer from "./authCrud";
import { authSlice, clearState } from "./authSlice";

const { actions } = authSlice;

export const getUserDetails = (email, password, rememberMe) => (dispatch) => {
  return requestFromServer
    .login(email, password, rememberMe)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.userDataFetched(response.data.data[0]));
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getUserData = (headers) => (dispatch) => {
  return requestFromServer
    .getUserData(headers)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.userDataFetched(response.data.data[0]));
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const updateUserPassword = (headers, body) => (dispatch) => {
  return requestFromServer
    .changeUserPassword(headers, body)
    .then((response) => {
      dispatch(getUserData(headers));
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const editUser = (headers, body) => (dispatch) => {
  return requestFromServer
    .userEdit(headers, body)
    .then((response) => {
      dispatch(getUserData(headers));
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const updateUserProfile = (headers, body) => (dispatch) => {
  return requestFromServer
    .updateUserData(headers, body)
    .then((response) => {
      if (response.status === 200) {
        dispatch(getUserData(headers));
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getUserLeads = (headers) => (dispatch) => {
  return requestFromServer
    .getUserLeadById(headers)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getBusinessLeads = (headers, id) => (dispatch) => {
  return requestFromServer
    .getBusinessLead(headers, id)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getUserCards = (headers) => (dispatch) => {
  return requestFromServer
    .getAllUserCards(headers)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.cardDataFetched(response.data));
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getUserCardById = (headers, id) => (dispatch) => {
  return requestFromServer
    .getUserCardById(headers, id)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.cardDataFetched(response.data.data));
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getUserCardProfileById = (headers, id) => (dispatch) => {
  return requestFromServer
    .getUserCardProfileById(headers, id)
    .then((response) => {
      if (response.status === 200) {
        dispatch(clearState());
        dispatch(actions.currentProfileFetched(response.data.data));
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const changeUserCardProfile =
  (headers, profileId, cardId) => (dispatch) => {
    return requestFromServer
      .changeUserProfile(headers, profileId, cardId)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getUserCards(headers)).then((res) => {
            dispatch(actions.cardDataFetched(res.data));
          });
          return response;
        } else {
          return null;
        }
      })
      .catch((error) => {
        return error;
      });
  };

export const changeUserCardProfileforEditPage =
  (headers, profileId, cardId) => (dispatch) => {
    return requestFromServer
      .changeUserProfile(headers, profileId, cardId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

export const changeUserCardPublishStatus =
  (headers, cardId, publishStatus) => (dispatch) => {
    return requestFromServer
      .publishUserCard(headers, cardId, publishStatus)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getUserCards(headers)).then((res) => {
            dispatch(actions.cardDataFetched(res.data));
          });
          return response;
        } else {
          return null;
        }
      })
      .catch((error) => {
        return error;
      });
  };

export const deleteUserCardById = (headers, cardId) => (dispatch) => {
  return requestFromServer
    .deleteUserCard(headers, cardId)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const addProfileToCard = (headers, body) => (dispatch) => {
  return requestFromServer
    .addProfile(headers, body)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const deleteProfileFromCard =
  (headers, cardId, profileId) => (dispatch) => {
    return requestFromServer
      .deleteProfile(headers, cardId, profileId)
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else {
          return null;
        }
      })
      .catch((error) => {
        return error;
      });
  };

export const getProfileTypes = (headers) => (dispatch) => {
  return requestFromServer
    .getProfileTypes(headers)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.profileTypesFetched(response.data.data));
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const sendCustomLink =
  (headers, cardId, profileId, customLink, id) => (dispatch) => {
    return requestFromServer
      .profileCustomLink(headers, cardId, profileId, customLink, id)
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else {
          return null;
        }
      })
      .catch((error) => {
        return error;
      });
  };

export const sendCompleteProfileData =
  (headers, cardId, profileId, body) => (dispatch) => {
    return requestFromServer
      .setProfileData(headers, cardId, profileId, body)
      .then((response) => {
        if (response.status === 200) {
          dispatch(actions.clearState());
          dispatch(getUserCardProfileById(headers, profileId));
          dispatch(getUserCardById(headers, cardId));
          return response;
        } else {
          return null;
        }
      })
      .catch((error) => {
        return error;
      });
  };

export const getCardCateogory = (headers) => (dispatch) => {
  return requestFromServer
    .getCardCategory(headers)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.cardCategoryFetched(response.data.data));
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getCardOptions = (headers) => (dispatch) => {
  return requestFromServer
    .getCardOptions(headers)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.cardOptionsFectched(response.data.data));
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getDisplayCardData = (cardId) => (dispatch) => {
  return requestFromServer
    .displayUserCard(cardId)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.displayDataFetched(response.data.data[0]));
        return response;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return error;
    });
};

// New card actions
export const newCardEmail = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return requestFromServer
      .newCardEmail(body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
          return response;
        } else {
          resolve(response);
          return response;
        }
      })
      .catch((error) => {
        reject(error);
        return error;
      });
  });
};

export const verifyOtp = (otp, cardId) => (dispatch) => {
  return requestFromServer
    .checkOtp(otp, cardId)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.fetchNewUserId(response.data.data[0]));
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};
export const newUserPassword = (password, cpassword, userId) => (dispatch) => {
  return requestFromServer
    .newUserPassword(password, cpassword, userId)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.clearState());
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const checkExisitingUser = (id) => (dispatch) => {
  return requestFromServer
    .testExistingUser(id)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getAllOrganisations = () => (dispatch) => {
  return requestFromServer
    .getAllOrganisation()
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.fetchOrganisations(response.data.data));
        return response;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const verifyOrganisationMember = (code) => (dispatch) => {
  return requestFromServer
    .verifyOrganisation(code)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return error;
    });
};

// Mutate departments

export const addDepartmentToBusiness = (headers, orgId, body) => (dispatch) => {
  return requestFromServer
    .addToDepartmentArray(headers, orgId, body)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const deleteDepartmentFromBusiness =
  (headers, orgId, body) => (dispatch) => {
    return requestFromServer
      .deleteDepartmentArrayItem(headers, orgId, body)
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else {
          return null;
        }
      })
      .catch((error) => {
        return error;
      });
  };

// Edit Card Name
export const editCardNameById = (headers, cardId, body) => (dispatch) => {
  return requestFromServer
    .editCardName(headers, cardId, body)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};
// Update email signature
export const updateEmailSignature = (headers, cardId, body) => (dispatch) => {
  return requestFromServer
    .editEmailSignature(headers, cardId, body)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

// Change card user & department
export const changeCardDepartment = (headers, body) => (dispatch) => {
  return requestFromServer
    .changeDepartment(headers, body)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

// Replace card
export const requestSimpleReplace = (headers, body) => (dispatch) => {
  return requestFromServer
    .requestSimpleReplace(headers, body)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};
export const requestReplaceCard = (headers, body) => (dispatch) => {
  return requestFromServer
    .replaceCardStripe(headers, body)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const requestAddOnCard = (headers, body) => (dispatch) => {
  return requestFromServer
    .addOnCardStripe(headers, body)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const generateLead = (headers, body) => (dispatch) => {
  return requestFromServer
    .generateLeads(headers, body)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return error;
    });
};

export const fetchImageUrl = (imageUrl, isOpen) => (dispatch) => {
  let load = {
    image: imageUrl,
    isVisible: isOpen,
  };
  let data = dispatch(actions.fetchImageUrl(load));
  return data;
};

export const walletLinkEmail = (headers, body) => (dispatch) => {
  return requestFromServer.sendWalletLink(headers, body).then((res) => {
    if (res.status === 200) {
      return { response: res, error: false }
    } else {
      return { response: [], error: true }
    }
  }).catch(() => {
    return { response: [], error: true }
  })
}