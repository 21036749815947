import React from "react";
import AvatarEditor from "react-avatar-editor";
import { useTranslation } from "react-i18next";
const ImageCrop = ({
  imageSrc,
  onCrop,
  setEditorRef,
  scaleValue,
  onScaleChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="editorModalContent clearfix text-center">
        <div className="cropCnt">
          <AvatarEditor
            backgroundColor={'rgba(0,0,0,0.3)'}
            image={imageSrc}
            border={50}
            scale={scaleValue}
            rotate={0}
            ref={setEditorRef}
            className="w-75 h-75"
          />
          <br />
          <label className="text-info">{t(`zoom`)}</label>
          <input
            style={{ width: "100%" }}
            type="range"
            value={scaleValue}
            name="points"
            min="1"
            step={"0.1"}
            max="10"
            onChange={onScaleChange}
          />
          <button onClick={onCrop} className="btn btn-primary">
            {t(`save`)}
          </button>
        </div>
      </div>
    </>
  );
};

export default ImageCrop;
