import { useState } from "react";
import Cookies from "js-cookie";
import { encode, decode } from "string-encode-decode";

const useToken = () => {
  const [token, setTokenInternal] = useState(() => {
    let de = Cookies.get("token");
    return decode(de);
  });
  const [userData, setUserData] = useState(() => {
    if (Cookies.get("user")) {
      let de = Cookies.get("user");
      return JSON.parse(decode(de));
    }
    return null;
  });
  const setToken = (newToken) => {
    let en = encode(newToken);
    Cookies.set(
      "token",
      en,
      {
        path: "/",
      },
      {
        expires: 5, //days
      }
    );
    setTokenInternal(en);
  };
  const setUser = (payload) => {
    let user = JSON.stringify(payload);
    let en = encode(user);
    Cookies.set("user", en, {
      path: "/",
    });
    setUserData(en);
  };
  return [token, setToken, setUser, userData];
};
export default useToken;
