import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Footer from "../components/footer/Footer";
import HelpButton from "../components/helpButton/HelpButton";
import Navbar from "../components/navbar/Navbar";
import * as actions from "../modules/Auth/_redux/authActions";
import useHeader from "../modules/Auth/_redux/authHeader";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Leads = () => {
  const dispatch = useDispatch();
  const [leads, setLeads] = useState([]);
  const { t } = useTranslation();
  const [header] = useHeader();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [itemPerPage, setItemPerPage] = useState(5);
  const pages = [];
  for (let i = 1; i <= Math.ceil(leads && leads.length / itemPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = leads && leads.slice(indexOfFirstItem, indexOfLastItem);

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };
  const renderPageNums = pages.map((number) => {
    return (
      <li
        className={`${currentPage === number ? "active" : ""} custom-bullets`}
        onClick={handleClick}
        key={number}
        id={number}
      >
        {number}
      </li>
    );
  });

  const handleNextbtn = () => {
    if (currentPage === renderPageNums.length) {
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevbtn = () => {
    if (currentPage.length === 0) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(1);
    }
  };
  useEffect(() => {
    dispatch(actions.getUserLeads(header)).then((response) => {
      if (response.status === 200) {
        setLeads(response.data.data[0]);
      } else {
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DownloadLeads = () => {
    const doc = new jsPDF();
    doc.autoTable({
      theme: "grid",
      html: "#leadsTable",
      styles: {
        cellPadding: 1,
        minCellWidth: 25,
        minCellHeight: 20,
        halign: "center",
        valign: "center",
        fontSize: 9,
        border: "1px solid",
        textColor: "black",
      },
      headStyles: { fillColor: "gray", halign: "center" },
      columnStyles: { halign: "center" },
    });
    doc.save("Leads.pdf");
  };

  return (
    <>
      <div class="page-wrapper landrick-theme">
        {/* <!-- Start Page Content --> */}
        <main class="page-content bg-light">
          <Navbar />

          <div class="container-fluid">
            <div class="layout-specing">
              <div class="row justify-content-center">
                <div class="col-xl-11 mt-4">
                  <div class="d-flex justify-content-end p-4 bg-white shadow rounded-top">
                    {/* <!-- <h6 class="fw-bold mb-0">Invoice List</h6> --> */}

                    <ul class="list-unstyled mb-0">
                      <li class="list-inline-item">
                        <div class="search-bar p-0 ms-2 m-2">
                          <div class="menu-search mb-0">
                            <form class="searchform">
                              <div>
                                <input
                                  type="text"
                                  class="form-control border rounded"
                                  name="s"
                                  id="s"
                                  placeholder="Filter Keywords..."
                                  onChange={(e) => setSearch(e.target.value)}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </li>

                      <li class="dropdown dropdown-primary list-inline-item">
                        <span
                          onClick={() => DownloadLeads()}
                          class="btn btn-primary"
                        >
                          {t(`download`)}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="table-responsive shadow rounded-bottom bg-white">
                    <table class="table table-center mb-0" id="leadsTable">
                      <thead class="text-center bg-dark text-white">
                        <tr>
                          <th class="border-bottom p-3">{t(`name`)}</th>
                          <th class="border-bottom p-3">{t(`company`)}</th>
                          <th class="border-bottom p-3">{t(`email`)}</th>
                          <th class="border-bottom p-3">{t(`mobile`)}</th>
                          <th class="border-bottom p-3">{t(`message`)}</th>
                          <th class="border-bottom p-3">{t(`date`)}</th>
                          <th class="border-bottom p-3">{t(`card_name`)}</th>
                          {/* <th class="text-end border-bottom p-3"></th> */}
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        {/* <!-- Start --> */}
                        {leads &&
                          leads !== [] &&
                          currentItems.length !== 0 &&
                          currentItems
                            // eslint-disable-next-line array-callback-return
                            .filter((value) => {
                              if (search === "") {
                                return value;
                              } else if (
                                value.name
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                value.company
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                value.email
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                value.card_name
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              ) {
                                return value;
                              }
                            })
                            .map((item) => (
                              <tr>
                                <td class="p-3">{item.name}</td>
                                <td class="p-3">{item.company}</td>
                                <td class="p-3">{item.email}</td>
                                <td class="p-3">{item.mobile}</td>
                                <td class="p-3">{item.message}</td>
                                <td class="p-3">{item.created_date}</td>
                                <td class="p-3">{item.card_name}</td>
                              </tr>
                            ))}

                        {/* <!-- End --> */}
                      </tbody>
                    </table>
                    {currentItems &&
                      currentItems
                        // eslint-disable-next-line array-callback-return
                        .filter((value) => {
                          if (search === "") {
                            return value;
                          } else if (
                            value.name
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            value.company
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            value.email
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            value.card_name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return value;
                          }
                        }).length === 0 && (
                        <p className="text-center">{t(`no_items_found`)}</p>
                      )}
                    {!currentItems && (
                      <p className="text-center">{t(`no_items_found`)}</p>
                    )}

                    <ul className="pageNumbers   ">
                      <li className="p-1">
                        <label>Page Size:</label>
                        <select
                          className="p-1"
                          onChange={(e) => setItemPerPage(e.target.value)}
                        >
                          {" "}
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                        </select>
                      </li>
                      <li>
                        <button
                          onClick={handlePrevbtn}
                          disabled={currentPage === pages[0] ? true : false}
                        >
                          {t(`previous`)}
                        </button>
                      </li>
                      {renderPageNums}
                      <li>
                        <button
                          onClick={handleNextbtn}
                          disabled={
                            currentPage === pages[pages.length - 1]
                              ? true
                              : false
                          }
                        >
                          {t(`next`)}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--end container--> */}

          {/* <!-- Footer Start --> */}
          <Footer />
        </main>
        {/* <!--End page-content" --> */}
      </div>
      {/* <!-- page-wrapper --> */}

      {/* <!-- help buttton start --> */}
      <HelpButton />
      {/* <!-- help buttton end --> */}

      {/* <!-- Delete Card Modal Content Start --> */}
    </>
  );
};

export default Leads;
