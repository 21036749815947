import React from "react";

const HelpButton = () => {
  return (
    <>
      <div className="btn-help">
        <a href="#!" className="btn btn-icon btn-pills btn-dark">
          <i className="uil uil-question fs-24"></i>
        </a>
      </div>
    </>
  );
};

export default HelpButton;
