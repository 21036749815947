import Cookies from "js-cookie";
import { useState } from "react";
import { decode } from "string-encode-decode";
import useToken from "../../../hooks/useCookie";

const useHeader = () => {
  const [, , , userData] = useToken();
  const [header] = useState(() => {
    let de = Cookies.get("token");
    return {
      Authorization: decode(de),
      // "x-authorization": userData && userData._id,
      "x-authorization": userData && userData,
      "Content-Type": "application/json",
    };
  });
  return [header];
};
export default useHeader;
