import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import { reduxBatch } from "@manaflair/redux-batch";

import { rootReducer } from "./rootReducer";

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  enhancers: [reduxBatch],
});

export default store;
