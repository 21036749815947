import React, { Component } from "react";
import ImageCrop from "./ImageCrop";

class ImageEditor extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      userProfilePic: "",
      editor: null,
      scaleValue: 1,
    };
    this.imageRef = React.createRef();
  }

  setEditorRef = (editor) => this.setState({ editor });

  onCrop = async () => {
    const { editor } = this.state;
    if (editor !== null) {
      const url = editor.getImage().toDataURL('image/jpeg', 0.95)
      await this.props.setProfileImage({ base64URL: url });
      this.setState({ userProfilePic: "", editor: null, scaleValue: 1 });
      if (this.props.onPopupCloseClick) {
        this.props.onPopupCloseClick();
      }
      this.setState({
        openCropper: false,
        selectedImage: null,
        fileUploadErrors: [],
      });
    }
  };
  onClear = () => {
    // this.imageRef.current.value = "";
    this.setState({
      openCropper: false,
      selectedImage: null,
      fileUploadErrors: [],
      userProfilePic: "",
      editor: null,
      scaleValue: 1,
    });
  };
  onScaleChange = (scaleChangeEvent) => {
    const scaleValue = parseFloat(scaleChangeEvent.target.value);
    this.setState({ scaleValue });
  };

  DataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  profilePicChange = (fileChangeEvent) => {
    const file = fileChangeEvent.target.files[0];
    if (file) {
      const { type } = file;
      if (
        !(
          type.endsWith("jpeg") ||
          type.endsWith("png") ||
          type.endsWith("jpg") ||
          type.endsWith("gif")
        )
      ) {
      } else {
        this.setState({
          openCropper: true,
          selectedImage: fileChangeEvent.target.files[0],
          fileUploadErrors: [],
        });
      }
    }
  };
  render() {
    console.log(this.state.selectedImage, "selected Image")
    return (
      <div className="overflow-hidden ">
        <div className="upload-btn-white">
          <input
            type="file"
            name="profilePicBtn"
            accept="image/png, image/jpeg"
            onChange={this.profilePicChange}
            className="m-3 msImage"
            ref={this.imageRef}
          />
        </div>
        {this.state.selectedImage && (
          <ImageCrop
            imageSrc={this.state.selectedImage}
            setEditorRef={this.setEditorRef}
            onCrop={this.onCrop}
            scaleValue={this.state.scaleValue}
            onScaleChange={this.onScaleChange}
          />
        )}
      </div>
    );
  }
}

export default ImageEditor;
