import RootRoutes from "./Routes";
import { Provider } from "react-redux";
import React from "react";
import "../redux/setupAxios";
import { A1SplashScreen } from "./components/splashScreen/SplashScreen";
import ErrorBoundary from "./utils/errorHandler";
import ToastMessage from "./components/common/ToastMessage";
import useReload from "./hooks/useReload";

function App({ store }) {

  const reload = useReload();
  reload();

  return (
    <>
      <Provider store={store}>
        <ErrorBoundary>
          <A1SplashScreen>
            <RootRoutes />
          </A1SplashScreen>
        </ErrorBoundary>
      </Provider>
      <ToastMessage />
    </>
  );
}

export default App;
