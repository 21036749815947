import React from "react";
import a1Dark from "../../../assets/images/logo/a1_card_dark.gif";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../languageDropdown/LanguageDropdown";
import Avatar from "../../../assets/images/icon/avatar.png";
import * as Icon from "react-feather";

const Navbar = () => {
  const { auth } = useSelector((state) => state);
  const location = useLocation();
  const { t } = useTranslation();

  const logOut = () => {
    Cookies.remove("token");
    Cookies.remove("user");
  };
  return (
    <>
      <div className="top-header">
        <div className="header-bar d-flex justify-content-between border-bottom">
          <div className="d-flex align-items-center">
            <Link to="/" className="logo-icon me-3 d-block">
              <img src={a1Dark} height="55" alt="a1 card logo" />
            </Link>
          </div>

          <ul className="list-unstyled mb-0">
            <li
              className={`list-inline-item mb-0 ms-1 navLink ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              <Link to="/">
                {" "}
                <Icon.Home size={22} className="fea mx-1 mb-1"></Icon.Home>
                {t(`home`)}
              </Link>
            </li>
            <li
              className={`list-inline-item navLink ${
                location.pathname === "/leads" ? "active" : ""
              }`}
            >
              <Link to="/leads">
                <Icon.Database
                  size={22}
                  className="fea mx-1 mb-1"
                ></Icon.Database>
                {t(`leads`)}
              </Link>
            </li>
            <LanguageDropdown />
            <li className="list-inline-item mb-0 ms-1">
              <div className="dropdown dropdown-primary">
                <button
                  type="button"
                  className="btn btn-soft-light dropdown-toggle p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={
                      auth.user && auth.user.profile_pic
                        ? auth.user.profile_pic
                        : Avatar
                    }
                    className="avatar avatar-ex-small rounded"
                    alt=""
                  />
                </button>

                <div
                  className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 py-3"
                  style={{ minWidth: 200 }}
                >
                  <Link
                    className="dropdown-item d-flex align-items-center text-dark pb-3"
                    to="/edit-user-profile"
                  >
                    <img
                      src={
                        auth.user && auth.user.profile_pic
                          ? auth.user.profile_pic
                          : Avatar
                      }
                      className="avatar avatar-md-sm rounded-circle border shadow"
                      alt=""
                    />
                    <div className="flex-1 ms-2">
                      <span className="d-block">
                        {auth.user && auth.user.full_name}
                      </span>
                      <small className="text-muted">
                        {auth.user && auth.user.designation}
                      </small>
                    </div>
                  </Link>
                  <span className="dropdown-item text-dark">
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-mail"></i>
                    </span>{" "}
                    {auth.user && auth.user.email}
                  </span>
                  <Link
                    className="dropdown-item text-dark text-capitalize view_mobile"
                    to="/"
                  >
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-home"></i>
                    </span>{" "}
                    {t(`dashboard`)}
                  </Link>

                  <Link
                    className="dropdown-item text-dark"
                    to="/edit-user-profile"
                  >
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-settings"></i>
                    </span>{" "}
                    {t(`profile`)}
                  </Link>
                  <Link
                    className="dropdown-item text-dark text-capitalize view_mobile"
                    to="/leads"
                  >
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-database"></i>
                    </span>{" "}
                    {t(`nav_lead`)}
                  </Link>
                  <Link
                    className="dropdown-item text-dark"
                    to="/change-password"
                  >
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-lock"></i>
                    </span>{" "}
                    {t(`change_password`)}
                  </Link>

                  <a
                    className="dropdown-item text-dark"
                    href="login"
                    onClick={logOut}
                  >
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-logout"></i>
                    </span>
                    {t(`logout`)}
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
