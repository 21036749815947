import React from 'react'
import { Fragment } from 'react'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../../../assets/css/toast.css'

const ToastMessage = () => {
    return (
        <Fragment>
            <ToastContainer/>
        </Fragment>
    )
}

export default ToastMessage
