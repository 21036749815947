import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  cards: null,
  currentProfileData: null,
  profileTypes: null,
  cardCategory: [],
  cardOptions: [],
  displayData: null,
  newUserId: null,
  organisations: [],
  imageUrl: null,
  isImageOpen: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    userDataFetched: (state, action) => {
      state.user = action.payload;
    },
    cardDataFetched: (state, action) => {
      state.cards = action.payload;
    },
    currentProfileFetched: (state, action) => {
      state.currentProfileData = action.payload;
    },
    profileTypesFetched: (state, action) => {
      state.profileTypes = action.payload;
    },
    cardCategoryFetched: (state, action) => {
      state.cardCategory = action.payload;
    },
    cardOptionsFectched: (state, action) => {
      state.cardOptions = action.payload;
    },
    displayDataFetched: (state, action) => {
      state.displayData = action.payload;
    },
    fetchNewUserId: (state, action) => {
      state.newUserId = action.payload;
    },
    fetchOrganisations: (state, action) => {
      state.organisations = action.payload;
    },
    fetchImageUrl: (state, action) => {
      state.imageUrl = action.payload.image;
      state.isImageOpen = action.payload.isVisible;
    },
    clearState: (state, action) => {
      state.currentProfileData = null;
      state.displayData = null;
      StaticRange.newUserId = null;
    },
  },
});

export const { clearState } = authSlice.actions;
