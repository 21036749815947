import axios from "axios";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response && error.response.status === 401) {
      const logout = () => {
        Cookies.remove("token");
        Cookies.remove("user");
        <Navigate to={"/login"} />;
      };
      logout();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);
